<div class="logo-wrapper" [routerLink]="['/']">
  <img src="/assets/images/dmc-logo.png" alt="DMC logo" />
</div>
<p class="sidebar-title">Menu</p>
<p-menu
  [model]="items"
  styleClass="sidebar-menu"
  [routerLinkActiveOptions]="{ exact: true }"
  routerLinkActive="active-menuitem"></p-menu>
<!-- Refresh screen button -->
<div class="refresh-block">
  <a
    href="javascript:;"
    (click)="isInProgress ? null : refreshAllScreen()"
    [ngClass]="{ disabledButton: isInProgress }">
    <img *ngIf="!isInProgress" src="/assets/images/refresh.svg" alt="refresh button" />
    <i *ngIf="isInProgress" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    <span>
      Click Here
      <b>Refresh all Screen</b>
    </span>
  </a>
</div>
<p-toast key="sidebar-toast"></p-toast>
