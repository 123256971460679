import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuardService as LoginGuard } from './services/guards/login-guard.service';
import { AuthGuardService as AuthGuard } from './services/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadComponent: () => import('./components/pages/login/login.component').then(m => m.LoginComponent),
    canActivate: [LoginGuard],
    data: {
      headerSideBar: false,
      pageTitle: 'Login'
    }
  },
  {
    path: 'directory',
    loadComponent: () => import('./components/pages/directory/directory.component').then(m => m.DirectoryComponent),
    canActivate: [AuthGuard],
    data: {
      headerSideBar: true,
      pageTitle: 'Ads Banner'
    }
  },
  /*{
    path: 'signup',
    loadComponent: () => import('./components/pages/signup/signup.component').then(m => m.SignupComponent),
    canActivate: []
  },
  {
    path: 'staff',
    loadComponent: () => import('./components/pages/staff/staff.component').then(m => m.StaffComponent)
  },
  {
    path: 'profile',
    loadComponent: () => import('./components/widgets/profile/profile.component').then(m => m.ProfileComponent)
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import('./components/widgets/change-password/change-password.component').then(m => m.ChangePasswordComponent)
  },*/
  {
    path: 'ads-banner',
    loadComponent: () => import('./components/pages/ads-banner/ads-banner.component').then(m => m.AdsBannerComponent),
    canActivate: [AuthGuard],
    data: {
      headerSideBar: true,
      pageTitle: 'Ads Banner'
    }
  },
  {
    path: 'all-floor',
    loadComponent: () => import('./components/pages/all-floors/all-floors.component').then(m => m.AllFloorsComponent),
    canActivate: [AuthGuard],
    data: {
      headerSideBar: true,
      pageTitle: 'All Floor'
    }
  },
  {
    path: '404',
    loadComponent: () =>
      import('./components/pages/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    data: {
      headerSideBar: false,
      pageTitle: '404, Page Not Found'
    }
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
