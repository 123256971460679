export const environment = {
  domainExt: window.location.host.split('.').pop(),
  apiUrl: 'https://qaapi.maptv.dallasmarketcenter.app/',
  mappedinApiGatewayUrl: 'https://api-gateway.mappedin.com/',
  identityTokenGatewayUrl: 'https://testids.unityedge.com/',
  unityApiGatewayUrl: 'https://testapi.unityedge.com/',
  bucketUrl: 'https://dmc-mappedin-qa-asset.s3.amazonaws.com/',
  production: true,
  showError: true
};
