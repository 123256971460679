import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  generateLoginToken: any = `${environment.apiUrl}dmcmap/api/user/login`;
  getDirectoryUrl: any = `${environment.apiUrl}dmcmap/api/directory/all`;
  getBannerUrl: any = `${environment.apiUrl}dmcmap/api/banner/getall`;
  uploadBannerUrl: any = `${environment.apiUrl}dmcmap/api/banner/upload`;
  deleteBannerUrl: any = `${environment.apiUrl}dmcmap/api/banner/delete`;
  floorPlanURL: any = `${environment.apiUrl}dmcmap/api/floorplan/details/all`;
  floorPlanSaveURL: any = `${environment.apiUrl}dmcmap/api/floorplan/save`;
  legendSaveURL: any = `${environment.apiUrl}dmcmap/api/legend/save`;
  opendailySaveURL: any = `${environment.apiUrl}dmcmap/api/opendaily/save`;
  mapGroupNameURL: any = `${environment.apiUrl}dmcmap/api/mapgroup/names`;
  notifyScreenURL: any = `${environment.apiUrl}dmcmap/api/system/notify`;
  notificationStatus: any = `${environment.apiUrl}dmcmap/api/notificationservice/status`;
  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) {}
  isAuthenticated(): boolean {
    const token = sessionStorage.getItem('access_token');
    if (token != null) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }
  public checkLogin(payload: any) {
    return this.http.post(this.generateLoginToken, payload);
  }
  public getAllDirectory() {
    return this.http.get(this.getDirectoryUrl);
  }
  public getAllBanner() {
    return this.http.get(this.getBannerUrl);
  }
  public uploadBanner(formData: any, params: any) {
    return this.http.post(this.uploadBannerUrl, formData, { params: params });
  }
  public deleteBanner(data: any) {
    return this.http.post(this.deleteBannerUrl, data);
  }
  public getCampusData() {
    const params = new HttpParams().append('sortBy', 'asc');
    return this.http.get(this.floorPlanURL, { params: params });
  }
  public saveFloorData(data: any) {
    return this.http.post(this.floorPlanSaveURL, data);
  }
  public saveLegendData(data: any) {
    return this.http.post(this.legendSaveURL, data);
  }
  public saveOpenDailyData(data: any) {
    return this.http.post(this.opendailySaveURL, data);
  }
  public getMapGroupData() {
    return this.http.get(this.mapGroupNameURL, {});
  }
  public notifyRefreshScreen() {
    return this.http.post(this.notifyScreenURL, {});
  }
  public getNotificationStatus() {
    return this.http.get(this.notificationStatus, {});
  }
}
