<div class="topbar-wrap">
  <div class="visible-sm">
    <img src="/assets/images/dmc-logo.png" alt="DMC logo" />
  </div>
  <div class="profile-btn-wrap">
    <p-menu #menu [model]="items" [popup]="true" styleClass="profile-menu"></p-menu>
    <button pButton type="button" (click)="menu.toggle($event)" class="profile-btn">
      <div class="img-wrap">
        {{ username }}
      </div>
    </button>
  </div>
</div>
