<div class="layout-wrapper" [ngClass]="{ login: loggedIn }">
  <div class="sidebar-wrapper" *ngIf="!loggedIn">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-wrapper">
    <app-topbar *ngIf="!loggedIn"></app-topbar>
    <router-outlet></router-outlet>
  </div>
</div>
<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style.width]="'425px'" appendTo="body">
</p-confirmDialog>
