import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { MenuItem, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [MenuModule, ToastModule, CommonModule]
})
export class SidebarComponent implements OnInit, OnDestroy {
  items: MenuItem[] | undefined;
  isInProgress = true;
  private stompClient: any;
  constructor(
    private commonService: CommonService,
    public messageService: MessageService
  ) {
    this.socketConnect();
  }
  async ngOnInit() {
    await this.checkPageRefresh();
    this.items = [
      // {
      //   label: 'All Directory',
      //   routerLink: ['/directory']
      // },
      {
        label: 'Ads Banner',
        routerLink: ['/ads-banner']
      },
      {
        label: 'All Floor',
        routerLink: ['/all-floor']
      }
    ];
  }
  socketConnect() {
    const options = {
      transports: ['websocket', 'xhr-polling'] // Specify allowed transports
    };
    this.stompClient = Stomp.over(new SockJS(`${environment.apiUrl}notificationservice`, null, options));

    // Configure heartbeats (values in milliseconds)
    // this.stompClient.heartbeatIncoming = 4000; // Client will send heartbeats every 20000ms
    // this.stompClient.heartbeatOutgoing = 4000; // Client expects to receive heartbeats at least every 20000ms
    console.log('stompClient:', this.stompClient);
    this.stompClient.connect(
      {},
      (frame: any) => {
        console.log('Connected to WebSocket', frame);
        this.stompClient.subscribe('/admin/notification', (message: any) => {
          const notificationMsg = JSON.parse(message?.body);
          if (notificationMsg.enableNotificationButton) {
            this.isInProgress = false;
            this.messageService.add({
              key: 'sidebar-toast',
              severity: 'success',
              summary: 'Success',
              detail: 'All screens refresh successfully'
            });
          }
          console.log('WebSocket admin:', message.body);
        });
        // this.stompClient.publish({ destination: '/allmap/notification', body: 'First Message' });
      },
      (error: any) => {
        console.error('Error connecting to WebSocket:', error);
        this.socketConnect();
      },
      (closeEvent: any) => {
        console.error('WebSocket Closed:', closeEvent);
        this.stompClient.deactivate();
        setTimeout(() => {
          this.socketConnect();
        }, 5000); // Reconnect after 5 seconds
      }
    );
    this.stompClient.deactivate();
    this.stompClient.activate();
  }
  async checkPageRefresh() {
    this.isInProgress = true;
    const response: any = await lastValueFrom(this.commonService.getNotificationStatus());
    if (response && response.success) {
      if (response.data.floorRebootServiceRunning) {
        this.isInProgress = true;
        this.messageService.add({
          key: 'sidebar-toast',
          severity: 'info',
          summary: 'Information',
          detail: 'Refreshing screens in the background...'
        });
      } else {
        this.isInProgress = false;
      }
    } else {
      this.isInProgress = false;
    }
  }
  async refreshAllScreen() {
    this.isInProgress = true;
    try {
      const response: any = await lastValueFrom(this.commonService.notifyRefreshScreen());
      if (response && response.success) {
        this.messageService.add({
          key: 'sidebar-toast',
          severity: 'success',
          summary: 'Success',
          detail: 'Notification successfully sent. Refreshing screens in the background...'
        });
      }
    } catch (error: any) {
      this.isInProgress = false;
      if (typeof error === 'string') {
        this.messageService.add({ severity: 'error', summary: 'Error', key: 'sidebar-toast', detail: error });
      } else {
        this.messageService.add({
          key: 'sidebar-toast',
          severity: 'error',
          summary: 'Error',
          detail: !error?.error?.success ? error?.error?.error?.message : error.message
        });
      }
    }
  }
  ngOnDestroy() {
    if (this.stompClient) {
      this.stompClient.deactivate();
    }
  }
}
