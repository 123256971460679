import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/widgets/sidebar/sidebar.component';
import { MenuModule } from 'primeng/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopbarComponent } from './components/widgets/topbar/topbar.component';
import { CommonService } from './services/common.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginGuardService } from './services/guards/login-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { AuthInterceptor } from './interceptors/auth.interceptors';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

export function tokenGetter() {
  return sessionStorage.getItem('access_token');
  // return localStorage.getItem('id_token');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SidebarComponent,
    MenuModule,
    ConfirmDialogModule,
    TopbarComponent,
    HttpClientModule,
    ToastModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [
          'localhost',
          'dev.dmc-p1.indiadevco.com',
          'qa.dmc-p1.indiadevco.com',
          'dev.maptv.dallasmarketcenter.app',
          'qa.maptv.dallasmarketcenter.app',
          'stag.maptv.dallasmarketcenter.app',
          'maptv.dallasmarketcenter.app'
        ],
        disallowedRoutes: []
      }
    })
  ],
  exports: [SidebarComponent],
  providers: [
    CommonService,
    MessageService,
    ConfirmationService,
    LoginGuardService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
