import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

@Component({
  selector: 'app-topbar',
  standalone: true,
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [MessageService],
  imports: [MenuModule]
})
export class TopbarComponent implements OnInit {
  items: MenuItem[] | undefined;
  username: any;
  constructor(private messageService: MessageService) {}

  ngOnInit() {
    const userDataString = sessionStorage.getItem('user_data');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      this.username = userData.username.charAt(0).toUpperCase();
    }

    this.items = [
      // {
      //   label: 'My Account'
      // },
      // {
      //   label: 'Change Password'
      // },
      // {
      //   separator: true
      // },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        styleClass: 'logout-link',
        routerLink: '/login',
        command: () => {
          sessionStorage.clear();
        }
      }
    ];
  }
}
