import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'DMC Admin Panel';
  loggedIn = true;
  public subscription: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.subscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (
          this.route &&
          this.route.root &&
          this.route.root.firstChild &&
          this.route.root.firstChild.snapshot &&
          this.route.root.firstChild.snapshot.data['headerSideBar'] === true
        ) {
          this.loggedIn = false;
        } else {
          this.loggedIn = true;
        }
      }
    });
  }
  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
