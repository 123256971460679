import { catchError, switchMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshTokenUrl = `${environment.apiUrl}dmcmap/api/user/refreshtoken`;
  isRefreshingToken = false;
  static addToken(req: HttpRequest<any> | any, token: string, refreshToken: string): HttpRequest<any> {
    let header: any = {};
    if (_.includes(req.url, environment.mappedinApiGatewayUrl)) {
      header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        'Access-Control-Allow-Credentials': 'true'
      };
    }
    if (_.includes(req.url, environment.identityTokenGatewayUrl)) {
      header = {
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }
    if (_.includes(req.url, environment.unityApiGatewayUrl)) {
      header['Authorization'] = 'Bearer ' + token;
    }
    if (_.includes(req.url, environment.apiUrl)) {
      header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        'Access-Control-Allow-Credentials': 'true'
      };
      if (_.includes(req.url, 'api/user/refreshtoken')) {
        // header['Authorization'] = 'Bearer ' + token;
        req.url = `${req.url}?key=${refreshToken}`;
      } else if (!(req.url.indexOf('user/login') !== -1)) {
        header['Authorization'] = 'Bearer ' + token;
      }
    }
    return req.clone({ setHeaders: header });
  }
  constructor(
    private router: Router,
    private injector: Injector,
    private http: HttpClient,
    private confirmationService: ConfirmationService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: any = sessionStorage.getItem('access_token');
    const refreshToken: any = sessionStorage.getItem('refresh_token');
    return next.handle(AuthInterceptor.addToken(req, token, refreshToken)).pipe(
      catchError((error: any): Observable<any> => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401: {
              const errorText: any = (<HttpErrorResponse>error).error;
              console.log('401 Unauthorized Error:', error, errorText);
              console.error('Unauthorized but error text is unknown. Forcing logout...');
              if (!(req.url.indexOf('/login') !== -1)) {
                console.log('UnauthorizedUnauthorized > 1');
                return this.logoutUser(errorText, true);
              } else {
                console.log('UnauthorizedUnauthorized > 2');
                return this.logoutUser(errorText, false);
              }
            }
            case 403: {
              const errorText: any = (<HttpErrorResponse>error).error;
              console.log('403 Forbidden Error:', error, errorText);
              return this.attemptTokenRefresh(req, next);
            }
            case 500: {
              const errormsg = (<HttpErrorResponse>error).error;
              console.log('errormsg:', errormsg);
              return throwError(error);
            }
            default:
              return throwError(error);
          }
        } else {
          return throwError('Auth interceptor detected that error is not an HTTP error');
        }
      })
    );
  }
  attemptTokenRefresh(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      console.log('Attempting to refresh token...', req, next);
      this.isRefreshingToken = true;
      return this.http.get<any>(this.refreshTokenUrl).pipe(
        switchMap((refreshResponse: any) => {
          sessionStorage.setItem('access_token', refreshResponse.data.token);
          sessionStorage.setItem('refresh_token', refreshResponse.data.refreshTokenHash);
          // const updatedRequest = req.clone({ setHeaders: { Authorization: `Bearer ${refreshResponse.data.token}` } });
          this.isRefreshingToken = false;
          return next.handle(
            AuthInterceptor.addToken(req, refreshResponse.data.token, refreshResponse.data.refreshTokenHash)
          );
          // return next.handle(updatedRequest);
        }),
        catchError((error: any) => {
          console.error('Error while attempting to refreshing token:', error);
          console.log('UnauthorizedUnauthorized > 4');
          this.isRefreshingToken = false;
          return this.logoutUser(error, true);
        })
      );
    } else {
      return throwError('Already attempting to refresh token!');
    }
  }
  logoutUser(msg: any, isConfirmShow: any): any {
    console.log('logoutUser:', msg, isConfirmShow);
    if (isConfirmShow) {
      this.confirmationService.confirm({
        header: 'Sorry, you have been logged out.',
        message: `${
          msg && typeof msg === 'string'
            ? msg
            : 'To protect your security, you have been logged out due to inactivity. We apologize for any inconvenience this may have caused.'
        }`,
        acceptLabel: 'Continue',
        rejectVisible: false,
        accept: () => {
          this.router.navigate(['login']);
          sessionStorage.clear();
          return throwError('Auth interceptor is logging the user out.');
        },
        reject: () => {
          this.router.navigate(['login']);
          sessionStorage.clear();
          return throwError('Auth interceptor is logging the user out.');
        }
      });
    } else {
      this.router.navigate(['login']);
      sessionStorage.clear();
      if (msg && msg['error'] && msg['error']['message']) {
        return throwError(msg['error']['message']);
      } else {
        return throwError('Auth interceptor is logging the user out.');
      }
    }
  }
}
